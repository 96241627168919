import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";
// const Rest_api_key=   process.env.REACT_APP_KAKAO_REST_API_KEY; //REST API KEY
// const redirect_uri =  process.env.REACT_APP_KAKAO_REDIRECT_URI; //Redirect URI
function KakaoLogin() {
    //방법1
    const location = useLocation();
    const KAKAO_CODE = location.search.split('=')[1];
    //방법2
    // const PARAMS = new URL(document.location).searchParams;
    // const KAKAO_C = PARAMS.get('code');


    const getKakaoToken = async () => {
      // console.log("여기도 들어오네요?")
      // fetch('https://kauth.kakao.com/oauth/token',{
      //     method : 'POST',
      //     headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      //     body: `grant_type=authorization_code&client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&code=${KAKAO_CODE}`,
      // })
      //     .then(res => res.json())
      //     .then(async data => {
      //         if (data.access_token) {
      const result = await axios.post(`/api/auth/kakao/userinfo?code=${KAKAO_CODE}`)
        .then((result) => {
          //   const result = await axios.post('/api/auth/kakao/userinfo', data.access_token,{
          //       headers: {
          //           'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
          //       }
          //   }).then((result) => {
          //spring에서 발급된 jwt localStorage 저장
          let now = new Date();
          let time = {
            expireTime: now.getTime() + result.data.tokenExpiresIn,
            userId: result.data.userId,
            nickName: result.data.nickName
          }
          localStorage.setItem("accessToken", result.data.accessToken);
          localStorage.setItem("tokenExpiresIn", JSON.stringify(time));

          if (result.data.firstCreate === true) {
            alert("환영합니다! 닉네임을 변경해주세요")
            window.location.href = "/changeNickName";
          } else {
            //메인 페이지로 이동
            window.location.href = "/";
          }
        }).catch((err) => {
          //에러발생 시 경고처리 후 login 페이지로 전환
          alert(err.response.data.detail);
          window.location.href = "/";
        })
    };

    useEffect(() => {
        if (!location.search) return;
        getKakaoToken()

    }, []);

    return
  // <div>KAKAO LOGIN</div>
}

export default KakaoLogin;