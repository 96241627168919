import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Main as MainSection} from './components';
import Page from './components/components/Page';
const SchoolEnv = () => {
  const theme = useTheme();
  return (
    <Main>
    <Page>
        {/*<Box bgcolor={theme.palette.alternate.main}>*/}
      <Box>
        {/*  <Box sx={{bgcolor: '#fff4c1'}}>*/}
          <Box
            sx={{
             // paddingTop: 5,
             // marginBottom: -10,
            }}
          >
          </Box>
          <Container maxWidth={800}>
        <MainSection />
          </Container>
        </Box>
      </Page>
    </Main>
  );
};

export default SchoolEnv;
