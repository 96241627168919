import React from 'react';

import Main from 'layouts/Main';
import Container from 'components/Container';
import Page from './components/components/Page';
import {
  Story
} from './components';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const Intro = () => {
  const theme = useTheme();
  return (
    <Main>
    <Page>
        {/*<Box bgcolor={theme.palette.alternate.main}>*/}
      <Box>
      {/*<Box sx={{bgcolor: '#fff4c1'}}>*/}
          <Box
            sx={{
             // paddingTop: 5,
             // marginBottom: -10,
            }}
          >
          </Box>
          {/*<Container maxWidth={891}>*/}
            <Story />
          {/*</Container>*/}
        </Box>
      </Page>
    </Main>
  );
};

export default Intro;
