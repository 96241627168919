import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Grid from '@mui/material/Grid';
import Page from './components/components/Page';
import Typography from '@mui/material/Typography';
import {
  Search,
} from './components';
import picture from '../../img/eduGoal/교목.png';
import kakaoLogin from '../../components/img/kakao_login_medium_narrow.png';

const BlogReachView = () => {
  const theme = useTheme();
  return (
    <Main>
    <Page>
        {/*<Box bgcolor={theme.palette.alternate.main}>*/}
      <Box>
          {/*<Box sx={{bgcolor: '#fff4c1'}}>*/}
          <Box
            sx={{
             // paddingTop: 5,
             // marginBottom: -10,
            }}
          >
          </Box>
          {/*<Container maxWidth={800}>*/}
              <Search />
            {/*<Box*/}
            {/*  alignItems={'center'}*/}
            {/*  component={'img'}*/}
            {/*  src={picture}*/}
            {/*  height="1000px"*/}
            {/*  sx={{ width: '100%' }}*/}
            {/*>*/}
            {/*</Box>*/}
          {/*</Container>*/}
        </Box>
      </Page>
    </Main>
  );
};

export default BlogReachView;
