import React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Categories,
  Hero,
  Pricings,
} from './components';

const Home = () => {

  return (
    <Main>
      <Hero />
      {/*<Box bgcolor={theme.palette.alternate.main}>*/}
      {/*  <Container>*/}
      {/*    <Categories/>*/}
      {/*  </Container>*/}
      {/*</Box>*/}

      {/*<Box sx={{bgcolor:'#fff4c1'}}>*/}
      <Box>
      {/*<Box bgcolor={theme.palette.alternate.main}>*/}
      {/*<Box sx={{bgcolor: '#F3D16E'}}>*/}
      <Container paddingY={{ xs: 4, sm: 4, md: 4 }}>
        <Pricings />
      </Container>
    </Box>
      <Divider />{/*가로 선 생성*/}

    </Main>
  );
};

export default Home;
