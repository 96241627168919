import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { BoardList, DeleteList } from './components';
import Page from './components/components/Page';

const Rank = () => {
  const theme = useTheme();
  return (
    <Main>
    <Page>
        {/*<Box bgcolor={theme.palette.alternate.main}>*/}
          <Box sx={{bgcolor: '#fff4c1'}}>
          <Box
            sx={{
              paddingTop: 5,
              marginBottom: -10,
            }}
          >
          </Box>
          <Container maxWidth={800}>
            <BoardList />
          </Container>
        </Box>
      </Page>
    </Main>
  );
};

export default Rank;
