/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import pic1 from 'img/main/free-icon-megaphone-6150465.png'
import pic2 from 'img/main/free-icon-daycare-12963157.png'
import pic3 from 'img/main/free-icon-nature-protection-2593833.png'
import pic4 from 'img/main/free-icon-goal-6596900.png'
import pic5 from 'img/main/free-icon-education-3976625.png'


import Pic8 from '../../../../img/flactionImg/free-icon-family-4547238.png';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

const mock = [
  {
    title: '인사말',
    avatar: pic1,
    href:'/intro/intro',
  },
  {
    title: '어린이집 소개',
    avatar: pic2,
    href:'/intro/schoolIntro',
  },
  {
    title: '어린이집 환경',
    avatar: pic3,
    href:'/intro/schoolEnv',
  },
  {
    title: '원훈 및 교육목표',
    avatar: pic4,
    href:'/edu/eduGoal',
  },
  {
    title: '교육과정운영',
    avatar: pic5,
    href:'/edu/program',
  },
];

const Categories = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={6} md={2.4} key={i}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <Box
                  component={Card}
                  padding={4}
                  borderRadius={2}
                  width={1}
                  height={1}
                  bgcolor={'alternate.main'}
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                >
                  <Box
                    position={'relative'}
                    display={'flex'}
                    justifyContent={'center'}
                    marginLeft={2}
                  >
                      <ListItemAvatar>
                        <Avatar src={item.avatar} />
                      </ListItemAvatar>
                  </Box>
                  <Typography
                    variant={'subtitle1'}
                    align={'center'}
                    sx={{ fontWeight: 500, marginTop: 2 }}
                    style={{fontFamily:'"Binggrae-Two'}}
                  >
                <Button href={item.href}>
                    {item.title}
                </Button>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Categories;
