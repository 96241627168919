const pages = {
  blog: [
    {
      title: '인사말',
      href: '/intro/intro',
    },
    {
      title: '원훈 및 교육목표',
      href: '/intro/eduGoal',
    },
    {
      title: '운영방침 및 현황',
      href: '/intro/schoolIntro',
    },
    {
      title: '어린이집 환경',
      href: '/intro/schoolEnv',
    },
    {
      title: '오시는 길',
      href: '/intro/comeMap',
    },
  ],
  company: [
    // {
    //   title: '교육과정운영',
    //   href: '/edu/program',
    // },
    {
      title: '중점 프로그램',
      href: '/edu/disProgram/disProgram01',
    },
    {
      title: '특별활동',
      href: '/edu/eduPlan/eng',
    },
    // {
    //   title: '선생님 방',
    //   href: '/edu/teacher/teacher',
    // },
  ],
  account: [
    {
      title: '까꿍반(만0세)',
      href: '/story/groupPicture/groupPicture0',
    },
    {
      title: '햇살반(만1세)',
      href: '/story/groupPicture/groupPicture1',
    },
    {
      title: '행복반(만2세)',
      href: '/story/groupPicture/groupPicture2',
    },
    {
      title: '지혜반(만3세)',
      href: '/story/groupPicture/groupPicture3',
    },
    {
      title: '슬기반(만4세)',
      href: '/story/groupPicture/groupPicture4',
    },
    {
      title: '창의반(만5세)',
      href: '/story/groupPicture/groupPicture5',
    },
    // {
    //   title: '프로그램',
    //   href: '/story/program/garden',
    // },
    // {
    //   title: '특별활동',
    //   href: '/story/activity/activity',
    // },
  ],
  secondary: [
    {
      title: '공지사항',
      href: '/news/notice/notice',
    },
    {
      title: '어린이집 소식',
      href: '/news/word/word',
    },
    // {
    //   title: '식단표',
    //   href: '/news/menu/menu',
    // },
    // {
    //   title: '가정 통신문',
    //   href: '/news/letter/letter',
    // },
    // {
    //   title: '서식 다운로드',
    //   href: '/news/form/form',
    // },
  ],
  portfolio: [
    {
      title: '모집요강',
      href: '/rank/rank/rank',
    },
    {
      title: '자료실',
      href: '/rank/form/form',
    },
  ],
};

export default pages;
