import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import axios from 'axios';

const MapHero = () => {
  const theme = useTheme();
  const pageName = "MyPageComeMap"; //페이지명
  const [picture1,setPicture1] = useState("");
  const getPicture1 = async () => {
    try {
      const resp = await (await axios.get("/api/main/getPicture1",{
        params:{pageName:pageName},
      }));
      setPicture1(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }

  useEffect(() => {
    getPicture1(); // 1) 게시글 상세정보 함수 호출
  }, []);

  const LeftSide = () => {

    return (
      <Box>
        {/*<Grid container spacing={4} direction={isMd ? 'row' : 'column'}>*/}
        <Box
          component={'img'}
          src={
            picture1.url
          }
          width={1}
          height={1}
          sx={{
            filter:
              theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
          }}
        />
        {/*</Grid>*/}
      </Box>
    );
  };


  const RightSide = () => {
    return (
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        title="map"
        marginHeight={0}
        marginWidth={0}
        scrolling="no"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3260.420323719368!2d129.05076467653157!3d35.19599787274779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356894b9b726fd41%3A0xb3fd220a56d87bf6!2z67aA7IKw6rK97LCw7LKt7Ja066aw7J207KeR!5e0!3m2!1sko!2skr!4v1716559038041!5m2!1sko!2skr"
        style={{
          minHeight: 100,
          filter:
            theme.palette.mode === 'dark'
              ? 'grayscale(0.5) opacity(0.7)'
              : 'none',
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        overflow: 'hidden',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1000 }}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
        >
          <Box width={1} order={{ xs: 2, md: 1 }}>
            {/*<Container>*/}
              <LeftSide />
            {/*</Container>*/}
          </Box>
          <Box
            sx={{
              flex: { xs: '0 0 100%', md: '0 0 50%' },
              position: 'relative',
              maxWidth: { xs: '100%', md: '50%' },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: '30vw' },
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    // marginTop: 3,  //좌측 이미지로 바뀌면서 제거
                    left: '0%',
                    width: 1,
                    height: 1,
                    position: { xs: 'relative', md: 'absolute' },
                  }}
                >
                  <RightSide />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      {/*<br/>*/}
      {/*<Divider />*/}
    </Box>
  );
};

export default MapHero;
