import React from 'react';

import {
  Home as MainPage,
  SchoolIntro as Intro_SchoolIntro,
  Intro as HelloIntro,
  SchoolEnv as Intro_SchoolEnv,
  EduProgram as Edu_EduProgram,
  EduGoal as Edu_EduGoal,
  KAKAO as KAKAO,
  MyPage as MyPage,
  ChangeGrade as ChangeGrade,
  ComeMap,
} from 'views';

import Home from './Home';
const routes = [
  {
    path: '/',
    renderer: (params = {}) => <MainPage {...params} />,
  },
  // {
  //   path: '/intro/schoolIntro',
  //   renderer: (params = {}) => <Intro_SchoolIntro {...params} />,
  // },
  // {
  //   path: '/intro/schoolEnv',
  //   renderer: (params = {}) => <Intro_SchoolEnv {...params} />,
  // },
  // {
  //   path: '/intro/intro',
  //   renderer: (params = {}) => <HelloIntro {...params} />,
  // },
  // {
  //   path: '/intro/eduGoal',
  //   renderer: (params = {}) => <Edu_EduGoal {...params} />,
  // },
  // {
  //   path: '/intro/comeMap',
  //   renderer: (params = {}) => <ComeMap {...params} />,
  // },
  {
    path: '/edu/program',
    renderer: (params = {}) => <Edu_EduProgram {...params} />,
  },
  {
    path: '/kakaoLogin',
    renderer: (params = {}) => <KAKAO {...params} />,
  },
  {
    path: '/changeNickName',
    renderer: (params = {}) => <MyPage {...params} />,
  },
  {
    path: '/changeGrade',
    renderer: (params = {}) => <ChangeGrade {...params} />,
  },
];

export default routes;
