import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import ThemeModeToggler from 'components/ThemeModeToggler';
import kakaoLogin from './img/kakao_login_medium_narrow.png';

const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
const Rest_api_key=   process.env.REACT_APP_KAKAO_REST_API_KEY; //REST API KEY
const redirect_uri =  process.env.REACT_APP_KAKAO_REDIRECT_URI; //Redirect URI
const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`

const handleLogin = ()=>{
  window.location.href = kakaoURL
}
const logout = () =>{
  // localStorage.removeItem("accessToken");
  localStorage.clear();
  alert("로그아웃 되었습니다");
  window.location.href = "/";
}

const myPage = () =>{
  const parseTokenExpiresIn = tokenExpiresIn;
  if(!parseTokenExpiresIn) {
    alert('로그인이 필요합니다.');
    window.location.href = "/"
    return null;
  }else {
    window.location.href = "/myPage/changeNickName";
  }
}

const TopNav = ({ colorInvert = false }) => {
  return (
    <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
      <Box marginRight={1}>
        <Box marginLeft={4}
             alignItems={'center'}
             component={'img'}
             src={kakaoLogin}
             height="28px"
             width={{ xs: 100, md: 150 }}
             onClick={handleLogin}
        />
      </Box>

      <Box marginRight={{ xs: 1, sm: 2 }}>
        <Link
          underline="none"
          component="a"
          onClick={myPage}
          target={'blank'}
          color={colorInvert ? 'common.white' : 'text.primary'}
        >
          마이페이지
        </Link>
      </Box>
      <Box marginRight={{ xs: 1, sm: 2 }}>
        <Link
          underline="none"
          component="a"
          onClick={logout}
          target={'blank'}
          color={colorInvert ? 'common.white' : 'text.primary'}
        >
          로그아웃
        </Link>
      </Box>
      <Box>
        <ThemeModeToggler />
      </Box>
    </Box>
  );
};

TopNav.propTypes = {
  colorInvert: PropTypes.bool,
};

export default TopNav;
