import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';

import Page from '../components/Page';
import Main from 'layouts/Main';
import axios from "axios";
import {Link, useLocation} from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Pagination from 'react-js-pagination';
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
const View = () => {

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
  const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
  const [detailView,setDetailView] = useState({id: null,title: null, author: null, views: null,boardList:null, content: null, viewFileDtoList:[],commentList:[]});
  let location = useLocation();
  const boardId = location.state.boardId;
  const comment = useRef("");

//페이징 처리
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(10);
  const handlePageChange = (page) => { setPage(page); };

  const getBoardView = async () => {
    try {
      const resp = await (await axios.get("/api/story/program/view", {
        params:{id:boardId,
                tableName:'program',
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setDetailView(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }
  const fileName = () => {
    const result = [];
    for (let i = 0; i < detailView.viewFileDtoList.length; i++) {
      result.push(<div key={i}>
        <Chip label={detailView.viewFileDtoList[i].name} variant="outlined" onClick={() => downloadFile(detailView.viewFileDtoList[i].url,detailView.viewFileDtoList[i].name)} />
        {/*<button onClick={() => downloadFile(detailView.viewFileDtoList[i].url,detailView.viewFileDtoList[i].name)}>{detailView.viewFileDtoList[i].name}</button>*/}
      </div>);
    }
    return result;
  };

  const downloadFile=async (url,fileName) =>{

    fetch(url, {
      method: "GET",
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);

        const aTag = document.createElement("a");

        aTag.href = blobURL;
        aTag.download = fileName;

        aTag.click();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const CommentSet = (e) => {
    comment.current = e.target.value
  }

  const deleteComment = async (commentId) =>{
    try {
    const result = await (await axios.get('/api/story/deleteComment/program',  {
      params:{id:commentId},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }));
    alert('삭제되었습니다.');
    window.location.reload();
  }catch (error){
    alert(error.response.data.resultMsg);
    window.location.reload();
  }
  }
  const saveComment = async() =>{
    try {
      let data = {
        content: comment.current,
        boardId: boardId,
        userId: tokenExpiresIn.userId,
        writer: tokenExpiresIn.nickName,
        boardList: detailView.boardList,
      }
      const result = await axios.post('/api/story/saveComment/program', JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      alert('등록되었습니다.');
      window.location.reload();
    }catch (error){
      alert(error.response.data.resultMsg);
      window.location.reload();
    }
  }

  const updateBtn = () =>{
    if(detailView.userId ==tokenExpiresIn.userId){
      return(<Button><Link to={'/story/program/update'} state={{boardId:detailView.id}}  style={{ textDecoration: 'none' ,color:'#f7b226',whiteSpace:'nowrap' }}>수정</Link></Button>);
    }
    return null;
  }
  const deleteBtn = () =>{
    if(detailView.userId ==tokenExpiresIn.userId){
      return(<Button type="button" onClick={deleteBoard} style={{whiteSpace:'nowrap'}}>삭제</Button>);
    }
    return null;
  }

  const deleteCommentBtn = (v) =>{
    if(detailView.userId ==tokenExpiresIn.userId || tokenExpiresIn.userId == v.userId){
      return (
      <Grid container item xs={12} md={12} spacing={2} marginTop={1} >
        <Grid item alignItems={'center'} xs={12} md={12}>
          
          <Typography variant={'subtitle2'}>
            {v.writer + '  '}
            <IconButton aria-label="delete" size="small" key={v.id} onClick={() => deleteComment(v.id)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Typography>
          
          <TextField
            id={v.id}
            label={v.createdDate}
            defaultValue={v.content}
            variant="standard"
            disabled={'true'}
            fullWidth
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
              width: '100%',
            }}
          />
        </Grid>
      </Grid>
      );
    }
    return (
        <Grid container item xs={12} md={12} spacing={2} marginTop={1} >
          <Grid item alignItems={'center'} xs={12} md={12}>
            <Typography variant={'subtitle2'}>
              {v.writer}
            </Typography>

            <TextField
              id="standard-read-only-input"
              label={v.createdDate}
              defaultValue={v.content}
              variant="standard"
              disabled={true}
              fullWidth
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
                width: '100%',
              }}
            />
          </Grid>
        </Grid>
    );
  }
  const deleteBoard = async() =>{
    try {
      const result =await (await axios.get('/api/story/delete/program', {
        params:{id:boardId,
          tableName:'program',
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      alert('삭제되었습니다.');
      window.location.href = "/story/program/garden";
    }catch (error){
      alert(error.response.data.resultMsg);
      window.location.reload();
    }
  }

  const  checkTokenExpire = () => {
    const parseTokenExpiresIn = tokenExpiresIn;
    if(!parseTokenExpiresIn){
      
      return null;
    }

    const now = new Date();

    if (now.getTime()>parseTokenExpiresIn){
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenExpiresIn');
      
      return null
    }
    
    return parseTokenExpiresIn
  }


  useEffect(() => {
    getBoardView(); // 1) 게시글 상세정보 함수 호출
  }, []);

  useEffect(() => {
    updateBtn(); // 1) 게시글 목록 조회 함수 호출
    fileName();
  }, [detailView]);

  useEffect(() => {
    checkTokenExpire(); // 권한체크
  }, []);

  return (
    <Box>
      <Box marginTop={{xs:2, md:2}}>
      </Box>
      
      <Box marginTop={{ xs: 1, md: 2 }} marginLeft={{ xs: 1, md: 2 }}>
        
        <Grid container spacing={8} direction={isMd ? 'row' : 'column'} marginTop={2} marginBottom={3}>
          
        <Box
          padding={{ xs: 3, sm: 4 }}
          // width={"100%"}
          sx = {{ width: { xs: "80%", sm: "90%", md: "100%" }  }}
          component={Card}
          boxShadow={1}
          data-aos="fade-up"
          justifyContent='flex-end'
          marginLeft={{ xs: 8, md: 8 }}
          marginRight={{ xs: 4 ,md: 2 }}
        >
          <Typography variant="h5" gutterBottom fontWeight={700}>
            {detailView.title}
          </Typography>
          <Divider/>{/*가로 선 생성*/}
          <Grid container  spacing={2} marginTop={0.5}>
          <Grid item alignItems={'center'} xs={8} md={10}>
            <Typography variant="subtitle2" gutterBottom fontWeight={500}>
           작성자: {detailView.author} / 작성일: {detailView.createdDate} / 조회수: {detailView.views}
            </Typography>
          </Grid>
          <Grid item alignItems={'center'}xs={4} md={2}>
          <ButtonGroup size="small" color="secondary"  aria-label="Small button group"
                       sx={{
                         display: 'flex',
                         justifyContent: 'flex-end',

                         bgcolor: 'background.paper',
                         borderRadius: 1,
                       }}
          >
              {updateBtn()}
              {deleteBtn()}
          </ButtonGroup>
          </Grid>
          </Grid>
          <Stack direction="row" spacing={1} marginTop={1}>
            {fileName()}
          </Stack>
          <Box paddingY={4}>
            <Divider />
          </Box>

          <form >
            <Grid container spacing={4}>

              <Grid item xs={12} md={12}>

                <Typography
                  style={{
                    // border: '1px solid black',
                    overflow: 'hidden',
                    overflowX: 'scroll', // added scroll
                  }}
                  paddingX={{md:4}}
                  // style={{ width: '80%' }}
                  width={'auto'}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html:
                    detailView.content,
                  }}
                >
                </Typography>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Divider />
              </Grid>
            </Grid>

            {/*댓글시작*/}

        <Grid container item xs={12} md={12} spacing={1} marginTop={1}>
          <Grid item alignItems={'center'} xs={8} md={10.5}>
            <TextField
              label="댓글을 입력하세요.."
                  variant="outlined"
                  name={'bio'}
                  multiline
                  // rows={5}
                  fullWidth
                  key={comment}
                  onChange={CommentSet}
                />
              </Grid>
              <Grid item alignItems={'center'} xs={4} md={1.5} marginTop={0.5}>
                <Button size={'large'} variant={'contained'} type={'button'} onClick={saveComment} sx={{whiteSpace:'nowrap'}}>
                  등록
                </Button>
              </Grid>


              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'center' }}
                  justifyContent={'space-between'}
                  width={1}
                  margin={'0 auto'}
                >
                  <Box width={1}>
                    {
                      detailView.commentList.slice(
                        items * (page - 1),
                        items * (page - 1) + items,
                      ).map((v, i) => {
                        return (
                          //댓글표시
                            deleteCommentBtn(v)
                        );
                      })
                    }
                  </Box>
                </Box>
              </Grid>

              <div style={{ width: '100%' }}>
                <Box alignContent={'center'}>
                  <PaginationBox>
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={items}
                      totalItemsCount={detailView.commentList.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}>
                    </Pagination>
                  </PaginationBox>
                </Box>
              </div>


          </Grid>

        </form>
      </Box>
        </Grid>
      </Box>
    </Box>
  );
};
const PaginationBox = styled.div`
  .pagination { display: flex; justify-content: center; margin-top: 15px;}
  ul { list-style: none; padding: 0; }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; 
  }
  ul.pagination li:first-child{ border-radius: 5px 0 0 5px; }
  ul.pagination li:last-child{ border-radius: 0 5px 5px 0; }
  ul.pagination li a { text-decoration: none; color: #337ab7; font-size: 1rem; }
  ul.pagination li.active a { color: white; }
  ul.pagination li.active { background-color: #337ab7; }
  ul.pagination li a:hover,
  ul.pagination li a.active { color: blue; }
  `
export default View;
