/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios';

const Story = () => {
  const theme = useTheme();
  const pageName = "MyPageIntro"; //페이지명
  const [picture1,setPicture1] = useState("");
  const [picture2,setPicture2] = useState("");

  const getPicture1 = async () => {
    try {
      const resp = await (await axios.get("/api/main/getPicture1",{
        params:{pageName:pageName},
      }));
      setPicture1(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }
  const getPicture2 = async () => {
    try {
      const resp = await (await axios.get("/api/main/getPicture2",{
        params:{pageName:pageName},
      }));
      setPicture2(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }


  useEffect(() => {
    getPicture1(); // 1) 게시글 상세정보 함수 호출
    getPicture2(); // 1) 게시글 상세정보 함수 호출
  }, []);

  return (
    <Box>
      {/*<Grid container spacing={4} direction={isMd ? 'row' : 'column'}>*/}
        <Box
          component={'img'}
          src={
            picture1.url
          }
          width={1}
          height={1}
          sx={{
            filter:
              theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
          }}
        />

      <Box
        component={'img'}
        src={
          picture2.url
        }
        width={1}
        height={1}
        sx={{
          filter:
            theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
        }}
      />
      {/*</Grid>*/}
    </Box>
  );
};

export default Story;
