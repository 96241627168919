import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import look from 'img/main/free-icon-playground-6433145.png';
import root from 'img/main/free-icon-search-3435091.png';
import calendar from 'img/main/free-icon-calendar-1254275.png';
import notice from 'img/main/free-icon-information-5672560.png';
import ImageListItem from '@mui/material/ImageListItem';
import AdjustIcon from '@mui/icons-material/Adjust';
import { createSvgIcon } from '@mui/material/utils';

  const Pricing = () => {
  const theme = useTheme();
  const [GalleryBoardList, setGalleryBoardList] = useState([]);
  const [noticeBoardList, setNoticeBoardList] = useState([]);
  const [menuBoardList, setMenuBoardList] = useState([]);

    const PlusIcon = createSvgIcon(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-6 w-6"
        color={'white'}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
      </svg>,
      'Plus',
    );

  const mock = [
  // {
  //   title: '어린이집 소식',
  //   price: '$22',
  //   // features: ['1 User', '1 App', 'Integrations'],
  //   features: wordBoardList.map((v, j)=>(v.title)) ,
  //   isHighlighted: false,
  //   detailPath: `/news/word/view`,
  //   listPath: "/news/word/word",
  //   id: wordBoardList.map((v,j)=>(v.id)),
  // },
{
  title: '공지사항',
  price: '$44',
  // features: [
  //   'All in Starter plan',
  //   'Google Ads',
  //   'SSO via Google',
  //   'API access',
  // ],
  features: noticeBoardList.map((v, j)=>(v.title)), //게시물 리스트
  isHighlighted: true,
  detailPath: `/news/notice/view`,
  listPath: "/news/notice/notice",
  id: noticeBoardList.map((v,j)=>(v.id)),
},
  // {
  //   title: '식단표',
  //   price: '$77',
  //   // features: [
  //   //   'All features',
  //   //   'Email support',
  //   //   'Google Ads',
  //   //   'SSO via Google',
  //   //   'API access',
  //   //   'Facebook Ads',
  //   // ],
  //   features: menuBoardList.map((v, j)=>(v.title)),
  //   isHighlighted: false,
  //   detailPath: `/news/menu/view`,
  //   listPath: "/news/menu/menu",
  //   id: menuBoardList.map((v,j)=>(v.id)),
  // },
  ];

  const groupPictureList = [
{
  title: '까꿍반',
  isHighlighted: true,
  detailPath: `/story/groupPicture/groupPicture0`,
  id: 'age0',
},
{
  title: '햇살반',
  isHighlighted: true,
  detailPath: `/story/groupPicture/groupPicture1`,
  id: 'age1',
},
{
  title: '행복반',
  isHighlighted: true,
  detailPath: `/story/groupPicture/groupPicture2`,
  id: 'age2',
},
{
  title: '지혜반',
  isHighlighted: true,
  detailPath: `/story/groupPicture/groupPicture3`,
  id: 'age3',
},
{
  title: '슬기반',
  isHighlighted: true,
  detailPath: `/story/groupPicture/groupPicture4`,
  id: 'age4',
},
{
  title: '창의반',
  isHighlighted: true,
  detailPath: `/story/groupPicture/groupPicture5`,
  id: 'age5',
},


  ];

  const getNewsList = async () => {
  try {

  const resp = await (await axios.get("/api/main/word", {

})).data;
  setGalleryBoardList(resp); // 3) boardList 변수에 할당

} catch (error) {
  alert(error.response.data.resultMsg);
  window.location.href = "/"
}
}

  const getNoticeList = async () => {
  try {

  const resp = await (await axios.get("/api/main/notice", {

})).data;
  setNoticeBoardList(resp); // 3) boardList 변수에 할당

} catch (error) {
  alert(error.response.data.resultMsg);
  window.location.href = "/"
}
}

  const getMenuList = async () => {
  try {

  const resp = await (await axios.get("/api/main/menu")).data;
  setMenuBoardList(resp); // 3) boardList 변수에 할당

} catch (error) {
  alert(error.response.data.resultMsg);
  window.location.href = "/"
}
}
  useEffect(() => {
  getNewsList(); // 1) 어린이집 소식 조회 함수 호출
  getNoticeList(); // 1) 공지사항 조회 함수 호출
  // getMenuList(); // 1) 식단표 조회 함수 호출
}, []);
  return (
  <Box>
  <Grid container spacing={4} >
  {mock.map((item, i) => (
    <Grid item xs={12} md={4} key={i} >
      <Box
        component={Card}
        height={1}
        display={'flex'}
        flexDirection={'column'}
        // boxShadow={item.isHighlighted ? 4 : 0}
        bgcolor={'#fff3f3'}
        // sx={{
        //   border: 1,
        //   borderColor: '#003478',
        // }}
      >
        <CardContent
          sx={{
            padding: 2,
          }}
        >
          <Box
            marginBottom={2}
            display={'flex'}
            justifyContent={'space-between'}
            bgcolor={'#003478'}
            alignItems="center"
            component={Card}
          >
            <Typography variant={'h6'}>
              <Box component={'span'} fontWeight={600} marginLeft={2} fontStyle={{ color: 'white'}}>
                {item.title}
              </Box>
            </Typography>
            <Button
              display={'flex'}
              alignItems={'baseline'}
              href={'/news/notice/notice'}
            >
              {/*<AddIcon*/}
              {/*  fontSize="large"*/}
              {/*/>*/}
              <PlusIcon />
            </Button>
          </Box>


          {/*공지사항 게시물 목록*/}
          <Grid container spacing={1} minHeight={140}>
            {item.features.map((feature, j) => (
              <Grid item xs={12} key={j}>
                <Box
                  component={ListItem}
                  disableGutters
                  width={'auto'}
                  padding={0}
                >
                  <Box
                    component={ListItemAvatar}
                    minWidth={'auto !important'}
                    marginRight={2}
                  >

                    {/*노란 이모티콘*/}
                    {/*<Box*/}
                    {/*  component={Avatar}*/}
                    {/*  bgcolor={theme.palette.secondary.main}*/}
                    {/*  width={20}*/}
                    {/*  height={20}*/}
                    {/*>*/}
                    {/*  <svg*/}
                    {/*    width={12}*/}
                    {/*    height={12}*/}
                    {/*    xmlns="http://www.w3.org/2000/svg"*/}
                    {/*    viewBox="0 0 20 20"*/}
                    {/*    fill="currentColor"*/}
                    {/*  >*/}
                    {/*    <path*/}
                    {/*      fillRule="evenodd"*/}
                    {/*      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"*/}
                    {/*      clipRule="evenodd"*/}
                    {/*    />*/}
                    {/*  </svg>*/}
                    {/*</Box>*/}
                  </Box>
                  <Box
                    component={Link}
                    primary={feature}
                    to={item.detailPath}
                    state={{ boardId: item.id[j] }}
                    style={{ textDecoration: 'none', color: 'black' }} //글자 아래 줄 표시
                    overflow={'hidden'}   //넘은글자 숨기기
                    whiteSpace={'nowrap'} //줄바꿈 방지
                    textOverflow={'ellipsis'} //말줄임표 추가
                    // fontWeight={600}
                    sx = {{
                      fontFamily: '"Noto Sans KR", sans-serif'
                    }}
                  >
                    -{feature}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>


        </CardContent>
        <Box flexGrow={1} />
      </Box>
    </Grid>
  ))}

  {/*우리반 소개 그리드*/}

  <Grid item xs={12} md={4}>
    <Box
      component={Card}
      height={1}
      display={'flex'}
      flexDirection={'column'}
      // boxShadow={item.isHighlighted ? 4 : 0}
      bgcolor={'#fff3f3'}
      // sx={{
      //   border: 1,
      //   borderColor: '#003478',
      // }}
    >
      <CardContent
        sx={{
          padding: 2,
        }}
      >
        <Box
          marginBottom={1}
          display={'flex'}
          justifyContent={'space-between'}
          bgcolor={'#003478'}
          alignItems="center"
          component={Card}
        >
          <Typography variant={'h6'}>
            <Box component={'span'} fontWeight={600} marginLeft={2} fontStyle={{ color: 'white'}}>
              우리반 소개
            </Box>
          </Typography>
          <Button
            display={'flex'}
            alignItems={'baseline'}
            href={'/story/groupPicture/groupPicture0'}
          >
            {/*<AddIcon*/}
            {/*  fontSize="large"*/}
            {/*/>*/}
            <PlusIcon />
          </Button>
        </Box>
        <Grid container spacing={1} >
          {groupPictureList.map((item, j) => (
            <Grid item xs={12} md={6} key={j}>
              <Box
                component={Card}
                disableGutters
                width={'auto'}
                padding={1}
                fontWeight={600}
                // bgcolor={theme.palette.alternate.main}
                // bgcolor={'#CEE3F6'}
                alignItems="center"
                textAlign="center"
                sx = {{
                fontFamily: '"Noto Sans KR", sans-serif'
                }}
                sx={{
                  border: 1,
                  borderColor: '#dedede',
                }}
              >
                <Link
                  primary={item.title}
                  to={item.detailPath}
                  underline="none"
                  style={{ textDecoration: 'none' ,color: 'black'}} //아래 줄 제거및 글자색 검정
                >
                  {item.title}
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>

      </CardContent>
      <Box flexGrow={1} />
    </Box>
  </Grid>

    {/*어린이집 소식 포토갤러리 그리드*/}

    <Grid item xs={12} md={4}>
      <Box
        component={Card}
        height={1}
        display={'flex'}
        flexDirection={'column'}
        // boxShadow={item.isHighlighted ? 4 : 0}
        bgcolor={'#fff3f3'}
        // sx={{
        //   border: 1,
        //   borderColor: '#003478',
        // }}
      >
        <CardContent
          sx={{
            padding: 2,
          }}
        >
          <Box
            marginBottom={1}
            display={'flex'}
            justifyContent={'space-between'}
            bgcolor={'#003478'}
            alignItems="center"
            component={Card}
          >
            <Typography variant={'h6'}>
              <Box component={'span'} fontWeight={600} marginLeft={2} fontStyle={{ color: 'white'}}>
                포토갤러리
              </Box>
            </Typography>
            <Button
              display={'flex'}
              alignItems={'baseline'}
              href={'/news/word/word'}
            >
              {/*<AddIcon*/}
              {/*  fontSize="large"*/}
              {/*/>*/}
              <PlusIcon />
            </Button>
          </Box>
          <Grid container spacing={1}>
            {GalleryBoardList.map((item, j) => (
              <Grid item maxHeight={100}  xs={12} md={6}  marginTop={2} >
                <Box component={Card} height={105} >
                <Link to={`/news/word/view`} state={{boardId : item.quillBoardId}} underline="none" style={{ textDecoration: 'none'}} >
                  <ImageListItem key={item.quillBoardId} marginLeft={7.5} >
                    <img variant="rounded" src={item.url} height={90} />
                  </ImageListItem>
                </Link>
              </Box>
              </Grid>
            ))}
          </Grid>

        </CardContent>
        <Box flexGrow={1} />
      </Box>
    </Grid>


    {/*아래 새로운 4가지 생성*/}


    {/*시설 둘러보기 시작 -입학 안내 link */}
    {/*시설 둘러보기*/}

    <Grid item xs={12} md={3}>
      <Link to={'/intro/schoolEnv'} underline="none" style={{ textDecoration: 'none' }}>
        <Box
          component={Card}
          height={1}
          display={'flex'}
          flexDirection={'column'}
          bgcolor={'#CEE3F6'}
          // sx={{
          //   border: 3,
          //   borderColor: '#003478',
          // }}
        >
          <CardContent
            sx={{
              padding: 2,
            }}
          >
            <Box
              component={ListItem}
              disableGutters
              width={'auto'}
              padding={0}
              justifyContent="center"
              alignItems="center"
            >
              {/*그림*/}
              <Box style={{ justifyContent: 'center', display: 'flex' }}>
                <Avatar variant="square" src={look} />
              </Box>

              <Typography variant={'h6'}>
                <Box component={'span'} fontWeight={600} marginLeft={1} fontStyle={{ color: '#003478'}}>
                  시설 둘러보기
                </Box>
              </Typography>
            </Box>
          </CardContent>
          <Box flexGrow={1} />
        </Box>
      </Link>
    </Grid>

    {/*시설 둘러보기 시작 -입학 안내 link */}
    {/*오시는 길*/}

    <Grid item xs={12} md={3}>
      <Link to={'/intro/comeMap'} underline="none" style={{ textDecoration: 'none' }}>
        <Box
          component={Card}
          height={1}
          display={'flex'}
          flexDirection={'column'}
          bgcolor={'#CEE3F6'}
          // sx={{
          //   border: 3,
          //   borderColor: '#003478',
          // }}
        >
          <CardContent
            sx={{
              padding: 2,
            }}
          >
            <Box
              component={ListItem}
              disableGutters
              width={'auto'}
              padding={0}
              justifyContent="center"
              alignItems="center"
            >
              {/*그림*/}
              <Box style={{ justifyContent: "center", display: "flex" }}>
                <Avatar variant="square" src={root} />
              </Box>

              <Typography variant={'h6'}>
                <Box component={'span'} fontWeight={600} marginLeft={1} fontStyle={{ color: '#003478'}}>
                  오시는 길
                </Box>
              </Typography>
            </Box>
          </CardContent>
          <Box flexGrow={1} />
        </Box>
      </Link>
    </Grid>


    {/*시설 둘러보기 시작 -입학 안내 link */}
    {/*행사계획*/}

    <Grid item xs={12} md={3}>
      <Link to={'/edu/teacher/teacher'} underline="none" style={{ textDecoration: 'none' }}>
        <Box
          component={Card}
          height={1}
          display={'flex'}
          flexDirection={'column'}
          bgcolor={'#CEE3F6'}
          // sx={{
          //   border: 3,
          //   borderColor: '#003478',
          // }}
        >
          <CardContent
            sx={{
              padding: 2,
            }}
          >
            <Box
              component={ListItem}
              disableGutters
              width={'auto'}
              padding={0}
              justifyContent="center"
              alignItems="center"
            >
              {/*그림*/}
              <Box style={{ justifyContent: "center", display: "flex" }}>
                <Avatar variant="square" src={calendar} />
              </Box>

              <Typography variant={'h6'}>
                <Box component={'span'} fontWeight={600} marginLeft={1} fontStyle={{ color: '#003478'}}>
                  행사계획
                </Box>
              </Typography>
            </Box>
          </CardContent>
          <Box flexGrow={1} />
        </Box>
      </Link>
    </Grid>

    {/*시설 둘러보기 시작 -입학 안내 link */}
    {/*입학안내*/}

    <Grid item xs={12} md={3}>
      <Link to={'/rank/rank/rank'} underline="none" style={{ textDecoration: 'none' }}>
        <Box
          component={Card}
          height={1}
          display={'flex'}
          flexDirection={'column'}
          bgcolor={'#CEE3F6'}
          // sx={{
          //   border: 3,
          //   borderColor: '#003478',
          // }}
        >
          <CardContent
            sx={{
              padding: 2,
            }}
          >
            <Box
              component={ListItem}
              disableGutters
              width={'auto'}
              padding={0}
              justifyContent="center"
              alignItems="center"
            >
              {/*그림*/}
              <Box style={{ justifyContent: "center", display: "flex" }}>
                <Avatar variant="square" src={notice} />
              </Box>

              <Typography variant={'h6'}>
                <Box component={'span'} fontWeight={600} marginLeft={1} fontStyle={{ color: '#003478'}}>
                  입학안내
                </Box>
              </Typography>
            </Box>
          </CardContent>
          <Box flexGrow={1} />
        </Box>
      </Link>
    </Grid>


  </Grid>
</Box>
)
;
};

export default Pricing;
