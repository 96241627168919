import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Page from './components/components/Page';
import {
  Faq,
} from './components';

const SchoolIntro = () => {
  const theme = useTheme();

  return (
    <Main>
    <Page>
        {/*<Box bgcolor={theme.palette.alternate.main}>*/}
          <Box>
          {/*<Box sx={{bgcolor: '#fff4c1'}}>*/}
          <Box
            sx={{
             // paddingTop: 5,
             // marginBottom: -10,
            }}
          >
          </Box>
          {/*<Container maxWidth={800}>*/}
            <Faq />
          {/*</Container>*/}
        </Box>
      </Page>
    </Main>
  );
};

export default SchoolIntro;
