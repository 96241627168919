import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import p1 from '../../../../img/schoolEnv/1.jpg'
import p2 from '../../../../img/schoolEnv/2.jpg'
import p3 from '../../../../img/schoolEnv/3.jpg'
import p4 from '../../../../img/schoolEnv/4.jpg'
import p5 from '../../../../img/schoolEnv/5.jpg'
import p6 from '../../../../img/schoolEnv/6.jpg'
import p7 from '../../../../img/schoolEnv/7.jpg'
import p8 from '../../../../img/schoolEnv/8.jpg'
import p9 from '../../../../img/schoolEnv/9.jpg'
import p10 from '../../../../img/schoolEnv/10.jpg'
import p11 from '../../../../img/schoolEnv/11.jpg'
import p12 from '../../../../img/schoolEnv/12.jpg'
import p13 from '../../../../img/schoolEnv/13.jpg'
import p14 from '../../../../img/schoolEnv/14.jpg'
import p15 from '../../../../img/schoolEnv/강당.jpg'
import p16 from '../../../../img/schoolEnv/도서관.jpg'
import p17 from '../../../../img/schoolEnv/유아반.jpg'
import p18 from '../../../../img/schoolEnv/18.jpg'
import p20 from '../../../../img/schoolEnv/20.jpg'

const mockLeftGrid = [

  {
    image: p16,
    description: 'Library',
    title: '도서관',
  },
  {
    image: p15,
    description: 'Auditorium ',
    title: '강당',
  },
  {
    image: p9,
    description:
      'ChildRoom1',
    title: '유아반1',
  },
  {
    image: p1,
    description:
      'ChildRoom2',
    title: '유아반2',
  },
];

const mockMiddleGrid = [
  {
    image: p6,
    description: 'Playground1',
    title: '놀이터1',
  },
  {
    image: p7,
    description:
      'Playground2',
    title: '놀이터2',
  },
  {
    image: p14,
    description: 'ChildRoom3',
    title: '유아반3',
  },
];

const mockRightGrid = [
  {
    image: p18,
    description: 'Panorama',
    title: '어린이집 전경',
  },
  {
    image: p17,
    description: 'ChildRoom4',
    title: '유아반4',
  },
  {
    image: p10,
    description: 'ChildRoom5',
    title: '유아반5',
  },
];

const Column = ({ data }) => {
  const theme = useTheme();
  return (
    <Box>
      {data.map((item, i) => (
        <Box
          key={i}
          sx={{
            marginBottom: { xs: 2, sm: 3 },
            '&:last-child': { marginBottom: 0 },
          }}
        >
          <Box
            boxShadow={1}
            sx={{
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 2,
              '&:hover': {
                '& img': {
                  transform: 'scale(1.2)',
                },
                '& .portfolio-massonry__main-item': {
                  bottom: 0,
                },
              },
            }}
          >
            <Box
              component={'img'}
              loading="lazy"
              height={1}
              width={1}
              src={item.image}
              alt="..."
              maxHeight={{ xs: 400, sm: 600, md: 1 }}
              sx={{
                transition: 'transform .7s ease !important',
                transform: 'scale(1.0)',
                objectFit: 'cover',
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              }}
            />
            <Box
              position={'absolute'}
              bottom={'-100%'}
              left={0}
              right={0}
              padding={4}
              bgcolor={'background.paper'}
              className={'portfolio-massonry__main-item'}
              sx={{ transition: 'bottom 0.3s ease 0s' }}
            >
              <Box
                component={'svg'}
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 100.1"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  transform: 'translateY(-90%)',
                  zIndex: 2,
                  width: 1,
                }}
              >
                <path
                  fill={theme.palette.background.paper}
                  d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                ></path>
              </Box>
              <Typography variant={'h6'} fontWeight={700} gutterBottom>
                {item.title}
              </Typography>
              <Typography>{item.description}</Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

Column.propTypes = {
  data: PropTypes.array.isRequired,
};

const Main = () => {
  return (

    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        {/*<Box>*/}
        {/*  <Typography fontWeight={700} variant={'h4'} gutterBottom*/}
        {/*              style={{color: '#43a047',*/}
        {/*                fontFamily:'"Noto Sans KR", sans-serif',*/}
        {/*  }}*/}
        {/*              marginBottom={3}*/}
        {/*  >*/}
        {/*    어린이집 환경*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Column data={mockLeftGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockMiddleGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockRightGrid} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
