import React from 'react';

import Main from 'layouts/Main';
import Container from 'components/Container';
import Page from './components/components/Page';
import {View} from './components';
import Box from '@mui/material/Box';
const ProgramView = () => {
  return (
          <Main>
      <Page>
        {/*<Box bgcolor={theme.palette.alternate.main}>*/}
        <Box sx={{bgcolor: '#fff4c1'}}>
          <Box
            sx={{
              paddingTop: 5,
              marginBottom: -10,
            }}
          >
          </Box>
          <Container maxWidth={800}>
          <View />
          </Container>
        </Box>
      </Page>
          </Main>
  );
};

export default ProgramView;
