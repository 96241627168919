import React from 'react';

import {
  DisProgram, DisProgramInsert, DisProgramUpdate,
  DisProgramView,

  EduPlan,EduPlanInsert , EduPlanUpdate, EduPlanView,

  Teacher,TeacherInsert , TeacherUpdate, TeacherView,

} from 'edu';
const routes = [
  //중점 프로그램
  {
    path: '/edu/disProgram/disProgram01',
    renderer: (params = {}) => <DisProgram {...params} />,
  },
  {
    path: '/edu/disProgram/disProgram02',
    renderer: (params = {}) => <DisProgram {...params} />,
  },
  {
    path: '/edu/disProgram/disProgram03',
    renderer: (params = {}) => <DisProgram {...params} />,
  },
  {
    path: '/edu/disProgram/disProgram04',
    renderer: (params = {}) => <DisProgram {...params} />,
  },
  {
    path: '/edu/disProgram/disProgram05',
    renderer: (params = {}) => <DisProgram {...params} />,
  },
  {
    path: '/edu/disProgram/disProgram06',
      renderer: (params = {}) => <DisProgram {...params} />,
  },
  {
    path: '/edu/disProgram/view',
    renderer: (params = {}) => <DisProgramView {...params} />,
  },
  {
    path: '/edu/disProgram/update',
    renderer: (params = {}) => <DisProgramUpdate {...params} />,
  },
  {
    path: '/edu/disProgram/insert',
    renderer: (params = {}) => <DisProgramInsert {...params} />,
  },
  {
    path: '/edu/eduPlan/eng',
    renderer: (params = {}) => <EduPlan {...params} />,
  },
  {
    path: '/edu/eduPlan/conco',
    renderer: (params = {}) => <EduPlan {...params} />,
  },
  {
    path: '/edu/eduPlan/creca',
    renderer: (params = {}) => <EduPlan {...params} />,
  },
  {
    path: '/edu/eduPlan/view',
    renderer: (params = {}) => <EduPlanView {...params} />,
  },
  {
    path: '/edu/eduPlan/update',
    renderer: (params = {}) => <EduPlanUpdate {...params} />,
  },
  {
    path: '/edu/eduPlan/insert',
    renderer: (params = {}) => <EduPlanInsert {...params} />,
  },
  {
    path: '/edu/teacher/teacher',
    renderer: (params = {}) => <Teacher {...params} />,
  },
  {
    path: '/edu/teacher/view',
    renderer: (params = {}) => <TeacherView {...params} />,
  },
  {
    path: '/edu/teacher/update',
    renderer: (params = {}) => <TeacherUpdate {...params} />,
  },
  {
    path: '/edu/teacher/insert',
    renderer: (params = {}) => <TeacherInsert {...params} />,
  },
];

export default routes;
