import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';
import Page from '../../myPage/ChangeGrade/components/components/Page';
import picture from '../../img/포돌.jpg';
const ChangeGrade = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Page>
        <Box bgcolor={theme.palette.alternate.main}>
          {/*<Box sx={{bgcolor: '#fff4c1'}}>*/}
          <Box
            sx={{
              // marginTop: -13,
              paddingTop: 5,
              marginBottom: -10
            }}
          >
          </Box>
          <Container maxWidth={800}>
            <Form />
          </Container>
        </Box>
      </Page>
    </Main>
  );
};

export default ChangeGrade;
