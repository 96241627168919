import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import Page from '../components/Page';
import Main from 'layouts/Main';
import axios from "axios";
import AWS from 'aws-sdk';
import ReactQuill from 'react-quill';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';

const Insert = () => {
  const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
  const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
  const [quillValue, setQuillVaule] = React.useState();
  const quillRef = useRef();
  const mainTitle = useRef();    // 타이틀

  const [files, setFiles] = useState([]);//첨부파일 부분
  let uploadFile = new Array();

  const [Selected, setSelected] = useState("groupPicture0");

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  AWS.config.update({
    region: "ap-northeast-2", // 버킷이 존재하는 리전을 문자열로 입력합니다. (Ex. "ap-northeast-2")
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.AWS_POOL_ID, // cognito 인증 풀에서 받아온 키를 문자열로 입력합니다. (Ex. "ap-northeast-2...")
    }),
  });


  useEffect(() => {
    checkTokenExpire(); // 1) 게시글 목록 조회 함수 호출
  }, []);

  //만료시간 체크
  const checkTokenExpire = () => {
    const parseTokenExpiresIn = tokenExpiresIn.expireTime;
    if (!parseTokenExpiresIn) {
      return null;
    }

    const now = new Date();

    if (now.getTime() > parseTokenExpiresIn) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenExpiresIn');
      return null
    }
    return parseTokenExpiresIn
  }

  const handleFileChange = (e) => {
    const input = document.createElement('input');
    // addFileInput.current.click();
    // 속성 써주기
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    //다중선택
    input.setAttribute('multiple', '');
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    input.addEventListener('change', async () => {
      try {

        for (const file of input.files) {
          if (file.name.length > 100){
            alert("첨부파일의 파일명이 너무 깁니다.")
            throw e;
          }
        }
      for (const file of input.files) {
        setFiles(files => [...files, file]);//첨부파일
      }
      }catch (e) {

      }
    })
  }

  const deleteAddFile = (name) => {
    // files에서 클릭한 버튼 제거
    setFiles(files.filter(file => file.name !== name));
  }

  const saveMainTitle = (e) => {
    mainTitle.current = e.target.value
  }

  // 이미지 처리를 하는 핸들러
  const imageHandler = () => {
    //이미지 파일을 첨부할 수 있는 input을 만든다.
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement('input');
    // 속성 써주기
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    //다중선택
    input.setAttribute('multiple', '');
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.


    //input에 이벤트리스너를 붙여, change 이벤트가 발생할 경우 2번째 인자인 함수를 실행시킨다.
    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener('change', async () => {
      // multer에 맞는 형식으로 데이터 만들어준다.
      //서버에서 FormData형식으로 받기 때문에 이에 맞는 데이터형식으로 만들어준다.
      const formData = new FormData();
      formData.append('dir','groupPicture/')
      formData.append('tableName','groupPicture')
      for (const file of input.files) {
        formData.append('img', file); //formData는 키-밸류 구조
      }
      // 백엔드 multer라우터에 이미지를 보낸다.
      try {
        const result = await axios.post('/api/imgList', formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          });

        for (const IMG of result.data) {
          const IMG_URL = IMG.url;
          // 이 URL을 img 태그의 src에 넣은 요소를 현재 에디터의 커서에 넣어주면 에디터 내에서 이미지가 나타난다
          // src가 base64가 아닌 짧은 URL이기 때문에 데이터베이스에 에디터의 전체 글 내용을 저장할 수있게된다
          // 이미지는 꼭 로컬 백엔드 uploads 폴더가 아닌 다른 곳에 저장해 URL로 사용하면된다.

          // 이미지 태그를 에디터에 써주기 - 여러 방법이 있다.
          const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
          // 1. 에디터 root의 innerHTML을 수정해주기
          // editor의 root는 에디터 컨텐츠들이 담겨있다. 거기에 img태그를 추가해준다.
          // 이미지를 업로드하면 -> 멀터에서 이미지 경로 URL을 받아와 -> 이미지 요소로 만들어 에디터 안에 넣어준다.
          // editor.root.innerHTML =
          //   editor.root.innerHTML + `<img src=${IMG_URL} /><br/>`; // 현재 있는 내용들 뒤에 써줘야한다.

          // 2. 현재 에디터 커서 위치값을 가져온다
          const range = editor.getSelection();
          // 가져온 위치에 이미지를 삽입한다
          editor.insertEmbed(range.index, 'image', IMG_URL);

          // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
          editor.setSelection(range.index + 1);
        }

      } catch (error) {
        alert(error.response.data.resultMsg);
        window.location.href = "/story/groupPicture/groupPicture0"
      }
    });
  }

  const insertButton = async () => {
    if(!mainTitle.current){
      alert("제목을 입력해주세요");
    }else{
      //마지막 결정된 첨부파일 먼저 aws 다녀와서 url받아둬야 한다.
      try {
        if (files.length > 0) {
          const formData = new FormData();
          formData.append('dir','groupPicture/')
          formData.append('tableName','groupPicture')
          for (const file of files) {
            formData.append('img', file); //formData는 키-밸류 구조
          }
          // 백엔드 multer라우터에 이미지를 보낸다.
          try {
            const result = await axios.post('/api/imgList', formData,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                }
              });

            for (const file of result.data) {
              // setDbFiles(dbFiles => [...dbFiles, file]);//첨부파일
              uploadFile.push(file);//addFile DB에 추가할 첨부파일 목록
            }
          } catch (error) {
            alert(error.response.data.resultMsg);
            window.location.href = "/story/groupPicture/groupPicture0"
            return;
          }
        }

        try {//백엔드에 데이터 보내기
          let data = {
            userId: tokenExpiresIn.userId,
            author: tokenExpiresIn.nickName,
            views: 0,
            content: quillValue,
            title: mainTitle.current,
            boardList: Selected,
            uploadFileDtoList: uploadFile,
          }
          const result = await axios.post('/api/story/insert/groupPicture', JSON.stringify(data), {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            }
          });
          alert('등록되었습니다.');
          window.location.href = "/story/groupPicture/groupPicture0"
        } catch (error) {
          alert(error.response.data.resultMsg);
          // window.location.reload();
          window.location.href = "/story/groupPicture/groupPicture0"
          return;
        }
      }catch (error){}}
  }


  const fileName = (files) => {
    const result = [];
    for (let i = 0; i < files.length; i++) {
      result.push(<div key={i}>
        <Chip label={files[i].name} variant="outlined" onDelete={() => deleteAddFile(files[i].name)} />
      </div>);
    }
    return result;
  };

  const selectBox = () => {
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            게시판 목록
          </InputLabel>
          <NativeSelect
            inputProps={{
              name: 'boardList',
              id: 'uncontrolled-native',
            }}
            onChange={handleSelect}
            value={Selected}
            sx={{ m: 1, width: '60%' }}
          >
            <option key="groupPicture0" value={'groupPicture0'}>까꿍반</option>
            <option key="groupPicture1" value={'groupPicture1'}>햇살반</option>
            <option key="groupPicture2" value={'groupPicture2'}>행복반</option>
            <option key="groupPicture3" value={'groupPicture3'}>지혜반</option>
            <option key="groupPicture4" value={'groupPicture4'}>슬기반</option>
            <option key="groupPicture5" value={'groupPicture5'}>창의반</option>
          </NativeSelect>
        </FormControl>
      </Box>
    );
  };

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  // 옵션에 상응하는 포맷, 추가해주지 않으면 text editor에 적용된 스타일을 볼수 없음
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'align',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'background',
    'color',
    'link',
    'image',
    'video',
    'height',
    "width",
    "float",
    "table",
  ];

  // quill에서 사용할 모듈
  // useMemo를 사용하여 modules가 렌더링 시 에디터가 사라지는 버그를 방지
  const modules = useMemo(() => {
    return {
      toolbar: {
        container:
          [
            // [{ 'font': [] }],    //웹사이즈 기본폰트를 사용하기위해 제거
            [{header: "1"}, {header: "2"}, {'header': [1, 2, 3, 4, false]}], ['bold', 'italic', 'underline', 'strike'],
            [{'color': []}, {'background': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'align': []}],
            ['link', 'image'],
          ],
        handlers: {
          image: imageHandler
          // mycustom : videoHandler
        },
      },
      imageActions: {},
      imageFormats: {},
      // imageDrop: true,

    }
  }, [])
  return (
    <Box>
      <Box marginTop={{xs:2, md:2}}>
      </Box>
      
      <Box marginTop={{ xs: 1, md: 2 }} marginLeft={{ xs: 1, md: 2 }}>
        
        <Grid container spacing={8} direction={isMd ? 'row' : 'column'} marginTop={2} marginBottom={3}>
          
        <Box
          padding={{ xs: 3, sm: 4 }}
          // width={"100%"}
          sx = {{ width: { xs: "80%", sm: "90%", md: "100%" }  }}
          component={Card}
          boxShadow={1}
          data-aos="fade-up"
          justifyContent='flex-end'
          marginLeft={{ xs: 8, md: 8 }}
          marginRight={{ xs: 4 ,md: 2 }}
        >

          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginBottom={1}>

            <Box marginRight={1}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              onClick={handleFileChange}
            >
              첨부파일 업로드
            </Button>
            </Box>

          <Button variant="contained" color="success" onClick={insertButton}>
            저장하기
          </Button>
          </Box>

          {/*게시판목록 선택*/}

          {selectBox()}
          
          <TextField
            id="outlined-start-adornment"
            sx={{ m: 1, width: '60%' }}
            key={mainTitle}
            onChange={saveMainTitle}
            size="small"
            placeholder="제목을 입력하세요"
            InputProps={{
              startAdornment: <InputAdornment position="start">제목</InputAdornment>,
            }}
          />

          <Box marginTop={2} marginBottom={1}>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'left',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
            {fileName(files)}
            </Paper>
          </Box>

          <Box paddingY={4}>
            <Divider />
          </Box>
          <ReactQuill
            style={{width: "97%", height: "600px"}}
            placeholder="Quill Content"
            value={quillValue}
            ref={quillRef}
            className="form-control text-editor"
            theme='snow'
            modules={modules}
            formats={formats}
            onChange={(e) => {
              setQuillVaule(e);
            }}

          />
          <Box paddingY={4}>

          </Box>
        </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default Insert;
