import React from 'react';

import {
  Rank, RankInsert , RankUpdate, RankView,
  Form, FormInsert, FormUpdate, FormView

} from 'rank';
const routes = [
  {
    path: '/rank/rank/rank',
    renderer: (params = {}) => <Rank {...params} />,
  },
  {
    path: '/rank/rank/view',
    renderer: (params = {}) => <RankView {...params} />,
  },
  {
    path: '/rank/rank/update',
    renderer: (params = {}) => <RankUpdate {...params} />,
  },
  {
    path: '/rank/rank/insert',
    renderer: (params = {}) => <RankInsert {...params} />,
  },
  //서식 다운로드
  {
    path: '/rank/form/form',
    renderer: (params = {}) => <Form {...params} />,
  },
  {
    path: '/rank/form/view',
    renderer: (params = {}) => <FormView {...params} />,
  },
  {
    path: '/rank/form/update',
    renderer: (params = {}) => <FormUpdate {...params} />,
  },
  {
    path: '/rank/form/insert',
    renderer: (params = {}) => <FormInsert {...params} />,
  },
];

export default routes;
