/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { visuallyHidden } from '@mui/utils';

const Form = () => {
  const [boardList, setBoardList] = useState([]);
  const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
  const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));

  const [picture1,setPicture1] = useState("");
  const [picture2,setPicture2] = useState("");
  const [picture3,setPicture3] = useState("");
  const [picture4,setPicture4] = useState("");
  const [picture5,setPicture5] = useState("");
  const pageName = "MyPageMain"; //페이지명
  let newPicture1 = new Array();
  let newPicture2 = new Array();
  let newPicture3 = new Array();
  let newPicture4 = new Array();
  let newPicture5 = new Array();


  const getPicture1 = async () => {
    try {
      const resp = await (await axios.get("/api/myPage/getPicture1",{
        params:{
          pageName:pageName,
          order:1
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setPicture1(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }
  const getPicture2 = async () => {
    try {
      const resp = await (await axios.get("/api/myPage/getPicture2",{
        params:{
          pageName:pageName,
          order:2
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setPicture2(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }
  const getPicture3 = async () => {
    try {
      const resp = await (await axios.get("/api/myPage/getPicture3",{
        params:{
          pageName:pageName,
          order:3
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setPicture3(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }
  const getPicture4 = async () => {
    try {
      const resp = await (await axios.get("/api/myPage/getPicture4",{
        params:{
          pageName:pageName,
          order:4
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setPicture4(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }
  const getPicture5 = async () => {
    try {
      const resp = await (await axios.get("/api/myPage/getPicture5",{
        params:{
          pageName:pageName,
          order:5
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setPicture5(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }

  const handlePicture1Change =async (e) => {
    const input = document.createElement('input');
    let orderPicture1 = 1;
    // addFileInput.current.click();
    // 속성 써주기
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    //다중선택
    // input.setAttribute('multiple', '');
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    input.addEventListener('change', async () => {
      try {

        for (const file of input.files) {
          if (file.name.length > 100){
            alert("첨부파일의 파일명이 너무 깁니다.")
            throw e;
          }
        }

        newPicture1 = [];
        for (const file of input.files) {
          newPicture1.push(file)
          // setNewPicture2(newPicture1 => [...newPicture1, file]);//첨부파일
        }
        updatePicture(orderPicture1);
        return;

      }catch (e) {
        alert("첨부오류가 발생했습니다 다시 시도해주세요.")
        window.location.reload();
      }

    })
  }

  const handlePicture2Change =async (e) => {
    const input = document.createElement('input');
    let orderPicture2 = 2;
    // addFileInput.current.click();
    // 속성 써주기
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    //다중선택
    // input.setAttribute('multiple', '');
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    input.addEventListener('change', async () => {
      try {

        for (const file of input.files) {
          if (file.name.length > 100){
            alert("첨부파일의 파일명이 너무 깁니다.")
            throw e;
          }
        }

          newPicture2 = [];
        for (const file of input.files) {
          newPicture2.push(file)
          // setNewPicture2(newPicture2 => [...newPicture2, file]);//첨부파일
        }
            updatePicture(orderPicture2);
          return;

      }catch (e) {
        alert("첨부오류가 발생했습니다 다시 시도해주세요.")
        window.location.reload();
      }

    })
  }
  const handlePicture3Change =async (e) => {
    const input = document.createElement('input');
    let orderPicture3 = 3;
    // addFileInput.current.click();
    // 속성 써주기
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    //다중선택
    // input.setAttribute('multiple', '');
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    input.addEventListener('change', async () => {
      try {

        for (const file of input.files) {
          if (file.name.length > 100){
            alert("첨부파일의 파일명이 너무 깁니다.")
            throw e;
          }
        }

        newPicture3 = [];
        for (const file of input.files) {
          newPicture3.push(file)
          // setNewPicture2(newPicture1 => [...newPicture1, file]);//첨부파일
        }
        updatePicture(orderPicture3);
        return;

      }catch (e) {
        alert("첨부오류가 발생했습니다 다시 시도해주세요.")
        window.location.reload();
      }

    })
  }

  const handlePicture4Change =async (e) => {
    const input = document.createElement('input');
    let orderPicture4 = 4;
    // addFileInput.current.click();
    // 속성 써주기
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    //다중선택
    // input.setAttribute('multiple', '');
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    input.addEventListener('change', async () => {
      try {

        for (const file of input.files) {
          if (file.name.length > 100){
            alert("첨부파일의 파일명이 너무 깁니다.")
            throw e;
          }
        }

        newPicture4 = [];
        for (const file of input.files) {
          newPicture4.push(file)
          // setNewPicture2(newPicture1 => [...newPicture1, file]);//첨부파일
        }
        updatePicture(orderPicture4);
        return;

      }catch (e) {
        alert("첨부오류가 발생했습니다 다시 시도해주세요.")
        window.location.reload();
      }

    })
  }
  const handlePicture5Change =async (e) => {
    const input = document.createElement('input');
    let orderPicture5 = 5;
    // addFileInput.current.click();
    // 속성 써주기
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    //다중선택
    // input.setAttribute('multiple', '');
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    input.addEventListener('change', async () => {
      try {

        for (const file of input.files) {
          if (file.name.length > 100){
            alert("첨부파일의 파일명이 너무 깁니다.")
            throw e;
          }
        }

        newPicture5 = [];
        for (const file of input.files) {
          newPicture5.push(file)
          // setNewPicture2(newPicture1 => [...newPicture1, file]);//첨부파일
        }
        updatePicture(orderPicture5);
        return;

      }catch (e) {
        alert("첨부오류가 발생했습니다 다시 시도해주세요.")
        window.location.reload();
      }

    })
  }

  //사진 변경시 기존 사진 제거 및 새로운 사진 저장필요
  const updatePicture  = async (orderPicture) => {
    //마지막 결정된 첨부파일 먼저 aws 다녀와서 url받아둬야 한다.
    const mainPhotoFormData = new FormData();
    mainPhotoFormData.append('dir','myPage/')
    mainPhotoFormData.append('pageName','MyPageMain')
    mainPhotoFormData.append('orderPicture', orderPicture);
    for (const file of newPicture2) {
      mainPhotoFormData.append('img', file); //formData는 키-밸류 구조
    }

    switch (orderPicture) {
      case 1:
        for (const file of newPicture1) {
          mainPhotoFormData.append('img', file); //formData는 키-밸류 구조
        }
      case 2:
        for (const file of newPicture2) {
          mainPhotoFormData.append('img', file); //formData는 키-밸류 구조
        }
        case 3:
        for (const file of newPicture3) {
          mainPhotoFormData.append('img', file); //formData는 키-밸류 구조
        }
      case 4:
        for (const file of newPicture4) {
          mainPhotoFormData.append('img', file); //formData는 키-밸류 구조
        }
      case 5:
        for (const file of newPicture5) {
          mainPhotoFormData.append('img', file); //formData는 키-밸류 구조
        }
    }

    // 백엔드 multer라우터에 이미지를 보낸다.
    try {
      const result = await axios.post('/api/myPage/updateImg', mainPhotoFormData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });

      switch (orderPicture) {
        case 1:
          setPicture1(result.data)
          alert('변경되었습니다.');
          window.location.reload();
          return;
        case 2:
          setPicture2(result.data)
          alert('변경되었습니다.');
          window.location.reload();
          return;
          case 3:
          setPicture3(result.data)
          alert('변경되었습니다.');
          window.location.reload();
          return;
        case 4:
          setPicture4(result.data)
          alert('변경되었습니다.');
          window.location.reload();
          return;
          case 5:
          setPicture5(result.data)
          alert('변경되었습니다.');
          window.location.reload();
          return;
      }
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.reload();
      return;
    }
  }


  const deleteFile = async (pageName,order) => {
    try {
      switch (order){
        case 1:{
          if (picture1.name ==="" || picture1.name === null){
            alert("사진이 없습니다")
            window.location.reload();
            return;
          }
        }
        case 2:{
          if (picture2.name ==="" || picture2.name === null){
            alert("사진이 없습니다")
            window.location.reload();
            return;
          }
        }
        case 3:{
          if (picture3.name ==="" || picture3.name === null){
            alert("사진이 없습니다")
            window.location.reload();
            return;
          }
        }
        case 4:{
          if (picture4.name ==="" || picture4.name === null){
            alert("사진이 없습니다")
            window.location.reload();
            return;
          }
        }
        case 1:{
          if (picture5.name ==="" || picture5.name === null){
            alert("사진이 없습니다")
            window.location.reload();
            return;
          }
        }
      }
      const resp = await (await axios.get("/api/myPage/deletePicture",{
        params:
          {pageName:pageName,
           order:order
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      alert('삭제되었습니다.');
      window.location.reload();
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.reload();
    }
  }


  const  checkTokenExpire = () => {
    const parseTokenExpiresIn = tokenExpiresIn;
    if(!parseTokenExpiresIn){
      
      return null;
    }

    const now = new Date();

    if (now.getTime()>parseTokenExpiresIn){
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenExpiresIn');
      
      return null
    }
    
    return parseTokenExpiresIn
  }

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdDate');
  const [dense, setDense] = React.useState(false);

  const headCells = [
    {
      id: 'nickName',
      numeric: false,
      disablePadding: true,
      label: '사진 순서',
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: '사진명',
    },
    {
      id: 'role',
      numeric: true,
      disablePadding: false,
      label: '삭제여부',
    },
    {
      id: 'useYn',
      numeric: false,
      disablePadding: false,
      label: '변경',
    },
  ];

//게시판 헤더
  function EnhancedTableHead(props) {
    const { order, orderBy } =
      props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
              {/*</TableSortLabel>*/}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  useEffect(() => {
    getPicture1(); // 1) 게시글 상세정보 함수 호출
    getPicture2(); // 1) 게시글 상세정보 함수 호출
    getPicture3(); // 1) 게시글 상세정보 함수 호출
    getPicture4(); // 1) 게시글 상세정보 함수 호출
    getPicture5(); // 1) 게시글 상세정보 함수 호출
  }, []);

  useEffect(() => {
    checkTokenExpire(); // 1) 게시글 목록 조회 함수 호출
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.

return (
    <Box>
      <Box marginTop={{xs:2, md:2}}>
      </Box>

      {/*게시판목록*/}
      <Box marginTop={{ xs: 1, md: 2 }} marginLeft={{ xs: 1, md: 2 }}>

        <Box marginBottom={2}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            메인 사진변경
          </Typography>
        </Box>
          {/*저장버튼*/}
          {/*<Box*/}
          {/*  display="flex" flexDirection={{ xs: 'column', md: 'row' }}*/}
          {/*  marginBottom={2}*/}
          {/*  sx={{ width: '98%',justifyContent: 'flex-end'}}*/}
          {/*>*/}
          {/*    <Button*/}
          {/*      sx={{ height: 42, whiteSpace: 'nowrap' ,justifyContent: 'flex-end'}}*/}
          {/*      variant="contained"*/}
          {/*      color="primary"*/}
          {/*      size="medium"*/}
          {/*      maxWidth={{xs: 1 ,md:0.1}}*/}
          {/*      // onClick={}*/}
          {/*    >*/}
          {/*      저장하기*/}
          {/*    </Button>*/}
          {/*</Box>*/}

          {/*게시판목록 in */}
        <Paper sx={{ width: '98%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
              />
              <TableBody>

                    {/*Picture1*/}
                    <TableRow
                      hover
                      component={Box}
                      tabIndex={-1}
                      key={1}
                      sx={{ cursor: 'pointer' }}
                      style={{ textDecoration: 'none' }}
                    >
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell
                        component="th"
                        id={1}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        1
                      </TableCell>
                      <TableCell align="right">{truncate(picture1.name,30)}</TableCell>
                      <TableCell align="right"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                       onClick={() => deleteFile("MyPageMain",1)}>삭제</Button></TableCell>
                      <TableCell align="center"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                        onClick={handlePicture1Change}>사진 업로드</Button></TableCell>
                    </TableRow>

                     {/*Picture2*/}
                  <TableRow
                    hover
                    component={Box}
                    tabIndex={-1}
                    key={2}
                    sx={{ cursor: 'pointer' }}
                    style={{ textDecoration: 'none' }}
                  >
                    <TableCell padding="checkbox">
                    </TableCell>
                    <TableCell
                      component="th"
                      id={2}
                      scope="row"
                      padding="none"
                      align="center"
                    >
                      2
                    </TableCell>
                    <TableCell align="right">{truncate(picture2.name,30)}</TableCell>
                    <TableCell align="right"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                     onClick={() => deleteFile("MyPageMain",2)}>삭제</Button></TableCell>
                    <TableCell align="center"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                      onClick={handlePicture2Change}>사진 업로드</Button></TableCell>
                  </TableRow>

                {/*Picture3*/}
                <TableRow
                  hover
                  component={Box}
                  tabIndex={-1}
                  key={3}
                  sx={{ cursor: 'pointer' }}
                  style={{ textDecoration: 'none' }}
                >
                  <TableCell padding="checkbox">
                  </TableCell>
                  <TableCell
                    component="th"
                    id={3}
                    scope="row"
                    padding="none"
                    align="center"
                  >
                    3
                  </TableCell>
                  <TableCell align="right">{truncate(picture3.name,30)}</TableCell>
                  <TableCell align="right"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                   onClick={() => deleteFile("MyPageMain",3)}>삭제</Button></TableCell>
                  <TableCell align="center"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                    onClick={handlePicture3Change}>사진 업로드</Button></TableCell>
                </TableRow>

                {/*Picture4*/}
                <TableRow
                  hover
                  component={Box}
                  tabIndex={-1}
                  key={4}
                  sx={{ cursor: 'pointer' }}
                  style={{ textDecoration: 'none' }}
                >
                  <TableCell padding="checkbox">
                  </TableCell>
                  <TableCell
                    component="th"
                    id={2}
                    scope="row"
                    padding="none"
                    align="center"
                  >
                    4
                  </TableCell>
                  <TableCell align="right">{truncate(picture4.name,30)}</TableCell>
                  <TableCell align="right"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                   onClick={() => deleteFile("MyPageMain",4)}>삭제</Button></TableCell>
                  <TableCell align="center"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                    onClick={handlePicture4Change}>사진 업로드</Button></TableCell>
                </TableRow>

                {/*Picture5*/}
                <TableRow
                  hover
                  component={Box}
                  tabIndex={-1}
                  key={5}
                  sx={{ cursor: 'pointer' }}
                  style={{ textDecoration: 'none' }}
                >
                  <TableCell padding="checkbox">
                  </TableCell>
                  <TableCell
                    component="th"
                    id={5}
                    scope="row"
                    padding="none"
                    align="center"
                  >
                    5
                  </TableCell>
                  <TableCell align="right">{truncate(picture5.name,30)}</TableCell>
                  <TableCell align="right"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                   onClick={() => deleteFile("MyPageMain",5)}>삭제</Button></TableCell>
                  <TableCell align="center"><Button sx={{ height: 35 }} variant="outlined" size="small"
                                                    onClick={handlePicture5Change}>사진 업로드</Button></TableCell>
                </TableRow>


                  <TableRow
                    style={{
                      height: 35,
                    }}
                  >
                    <TableCell colSpan={5} />
                  </TableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

      </Box>
    </Box>
);
};

export default Form;
