import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';
import Page from '../../myPage/ChangeNickName/components/components/Page';
import picture from '../../img/포돌.jpg';
const ChangeNickName = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  return (
    <Main>
      <Page>
        <Box bgcolor={theme.palette.alternate.main}>
        {/*  <Box sx={{bgcolor: '#fff4c1'}}>*/}
          <Box
            sx={{
              paddingTop: 5,
              marginBottom: -13
            }}
          >
          </Box>

              <Grid container spacing={6}>
                <Grid
                  item
                  container
                  alignItems={'center'}
                  justifyContent={'center'}
                  xs={12}
                  md={6}
                >
                  <Form />
                </Grid>
                {isMd ? (
                  <Grid item container justifyContent={'center'} xs={12} md={6}>
                    <Box height={1} width={1} maxWidth={500}>
                      <Box
                        component={'img'}
                        src={
                          picture
                        }
                        width={1}
                        height={1}
                        sx={{
                          filter:
                            theme.palette.mode === 'dark'
                              ? 'brightness(0.8)'
                              : 'none',
                        }}
                      />
                    </Box>
                  </Grid>
                ) : null}
              </Grid>

        </Box>
      </Page>
    </Main>
  );
};

export default ChangeNickName;
