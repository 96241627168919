/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import axios from 'axios';
import ButtonGroup from '@mui/material/ButtonGroup';
import Pagination from 'react-js-pagination';
import styled from 'styled-components'
import Card from '@mui/material/Card';

const Form = () => {
  const [boardList, setBoardList] = useState([]);
  const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
  const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));

  const [viewGrade,setViewGrade] = useState("HUMAN");

  //페이징 처리
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(10);
  const handlePageChange = (page) => { setPage(page); };

  const getUserList = async () => {
    try {
      const resp = await (await axios.get("/api/userGradeList",{
        params:{grade:viewGrade},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setBoardList(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }

  const changeViewRole= (grade) => {
    setViewGrade(grade);
  }

  const showBtnName = (userId)=>{
    switch (viewGrade){
      case "HUMAN" :
        return  <Button variant="outlined" size="small" onClick={() => changUserGrade(userId)}>학부모로 등업</Button>
      case "MOM" :
        return  <Button variant="outlined" size="small" onClick={() => changUserGrade(userId)}>선생님으로 등업</Button>
      case "TEACHER":
        return <Button variant="outlined" size="small" onClick={() => changUserGrade(userId)}>학부모로 변경</Button>

    }
  }
  const changUserGrade = async (id) => {
    try {//백엔드에 데이터 보내기
      let data = {
        id : id,
        role : viewGrade,
      }

      const result = await axios.post('/api/changeGrade',  JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      alert('변경되었습니다.');
      window.location.reload();
    } catch (error) {
      alert(error.response.data.resultMsg);
      // window.location.reload();
      window.location.href = "/"
    }
  }
  const changeNickNameBtn = () =>{
      return (
        <Box marginBottom={{ xs: 1, sm: 0 }}>
          <Button
            size={'large'}
            variant={'outlined'}
            component={Link}
            href={'/changeNickName'}
            fullWidth
          >
            닉네임변경
          </Button>
        </Box>
      );
  }
  const  checkTokenExpire = () => {
    const parseTokenExpiresIn = tokenExpiresIn;
    if(!parseTokenExpiresIn){
      
      return null;
    }

    const now = new Date();

    if (now.getTime()>parseTokenExpiresIn){
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenExpiresIn');
      
      return null
    }
    
    return parseTokenExpiresIn
  }



  useEffect(() => {
    getUserList(); // 1) 게시글 상세정보 함수 호출
  }, [viewGrade]);

  useEffect(() => {
    checkTokenExpire(); // 1) 게시글 목록 조회 함수 호출
  }, []);

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          부산경찰청어린이집
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          등급변경
        </Typography>
      </Box>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button onClick={()=>changeViewRole("HUMAN")}>일반인</Button>
              <Button onClick={()=>changeViewRole("MOM")}>학부모</Button>
              <Button onClick={()=>changeViewRole("TEACHER")}>선생님</Button>
            </ButtonGroup>
            <Box>
            {
              boardList.slice(
                30 * (page - 1),
                30 * (page - 1) + 30
              ).map((v, i) => {
                return (
                  <Box key={i}

                       display={'flex'}
                       justifyContent={'space-between'}
                  >
                    {/*<Typography variant={'subtitle1'}>가입일:{v.createdDate}, 닉네임 = {v.nickName}, 등급 ={v.role}*/}

                    <Typography variant={'subtitle1'}>가입일:{v.createdDate},   닉네임 : {v.nickName}
                      {/*&emsp;*/}
                    </Typography>
                      {showBtnName(v.id)}

                  </Box>
                )
              })
            }
            </Box>
            <PaginationBox>
              <Pagination
                activePage={page}
                itemsCountPerPage={items}
                totalItemsCount={boardList.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}>
              </Pagination>
            </PaginationBox>
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              {changeNickNameBtn()}
              <Button size={'large'} variant={'contained'} type={'submit'} onClick={changUserGrade}>
                변경하기
              </Button>
            </Box>
          </Grid>
        </Grid>
    </Box>
  );
};
const PaginationBox = styled.div`
    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    ul.pagination li {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 1px solid #e2e2e2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
    }

    ul.pagination li:first-child {
        border-radius: 5px 0 0 5px;
    }

    ul.pagination li:last-child {
        border-radius: 0 5px 5px 0;
    }

    ul.pagination li a {
        text-decoration: none;
        color: #337ab7;
        font-size: 1rem;
    }

    ul.pagination li.active a {
        color: white;
    }

    ul.pagination li.active {
        background-color: #337ab7;
    }

    ul.pagination li a:hover,
    ul.pagination li a.active {
        color: blue;
    }
`
export default Form;
