import React from 'react';

import {
  ChangeNickName,
  ChangeGrade,
  ChangeIntro,
  ChangeEduGoal,
  ChangeSchoolIntro,
  ChangeMainPic,
  ChangeComeMap
} from 'myPage';
const routes = [
  {
    path: '/myPage/changeNickName',
    renderer: (params = {}) => <ChangeNickName {...params} />,
  },
{
    path: '/myPage/changeGrade',
    renderer: (params = {}) => <ChangeGrade {...params} />,
  },
  {
    path: '/myPage/changeIntro',
    renderer: (params = {}) => <ChangeIntro {...params} />,
  },
  {
    path: '/myPage/changeEduGoal',
    renderer: (params = {}) => <ChangeEduGoal {...params} />,
  },
  {
    path: '/myPage/ChangeSchoolIntro',
    renderer: (params = {}) => <ChangeSchoolIntro {...params} />,
  },
  {
    path: '/myPage/ChangeMainPic',
    renderer: (params = {}) => <ChangeMainPic {...params} />,
  },
  {
    path: '/myPage/ChangeComeMap',
    renderer: (params = {}) => <ChangeComeMap {...params} />,
  },
];

export default routes;
