import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import MainPhoto from '../../../../img/mainTitleImg/img.png';
import MainPhoto2 from '../../../../img/mainTitleImg/img_1.png';
import MainPhoto3 from '../../../../img/mainTitleImg/img_2.png';
import MainPhoto4 from '../../../../img/mainTitleImg/img_3.png';
import MainPhoto5 from '../../../../img/mainTitleImg/img_4.png';
import Container from 'components/Container';
// import bgImg from 'img/mainLogo/[크기변환]경찰청로고1최적본.jpg'
// import bgImg from 'img/mainLogo/[크기변환]경찰청로고1.jpg'
// import bgImg from 'img/mainLogo/투명처리조절1.png'
import bgImg from 'img/003_2.png'
import leftImg from 'img/main/그림1.png'


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import axios from 'axios';
// const imgs2 = [
//   MainPhoto,MainPhoto2,MainPhoto3,MainPhoto4,MainPhoto5
// ];

// const useLeftLogoStyle = makeStyles({
//   root: {
//     backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5 ), rgba(255, 255, 255, 0.5 )),url(${bgImg})`,
//     backgroundSize: 'contain',
//     // backgroundPosition: 'left 70px top',
//     minHeight: '400px',
//     justifyContent: 'center',
//     position: 'relative',
//     // opacity: 0.4,
//     // backgroundColor: 'rgba( 255, 255, 255, 0.5 )'
//   },
// });

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const pageName = "MyPageMain"; //페이지명
  const [imgs, setImgs] = useState([]);
  // const classes = useLeftLogoStyle();


  const getPicture = async () => {
    try {
      const resp = await (await axios.get("/api/main/mainPicture", {
        params:{pageName:pageName},
      }));
      for (const file of resp.data) {
        setImgs(imgs => [...imgs, file]);//첨부파일
      }
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }
  useEffect(() => {
    getPicture(); // 1) 게시글 상세정보 함수 호출
  }, []);


  // const LeftSide = () => (
  //   // <Box data-aos={isMd ? 'fade-right' : 'fade-up'} position={'relative'} marginLeft={{md:7}}>
  //   <Box data-aos={isMd ? 'fade-right' : 'fade-up'} position={'relative'} textAlign={'center'} alignItems={'center'}>
  //     <Box >
  //       <Typography variant="h6" component="p" color="text.secondary">
  //         아이와 부모 그리고 교사가 함께 행복한
  //       </Typography>
  //     </Box>
  //     <Box marginBottom={2}>
  //       <Typography variant="h3" color="text.primary" sx={{ fontWeight: 700 }} style={{fontFamily:'"Noto Sans KR", sans-serif'}} >
  //         부산 경찰청{' '}
  //         <Typography
  //           color={'#003478'}
  //           component={'span'}
  //           variant="h3"
  //           fontWeight={700}
  //           // sx={{
  //           //   background: `linear-gradient(180deg, transparent 82%, ${alpha(
  //           //     theme.palette.secondary.main,
  //           //     0.3,
  //           //   )} 0%)`,
  //           // }}
  //         >
  //           어린이집
  //         </Typography>
  //       </Typography>
  //     </Box>
  //   </Box>
  // );

  const LeftSide = () => (
      <Box bgcolor={'#003478'}>

      </Box>
    );

  const RightSide = () => {

    return (
      <Box
        sx={{
          height: { xs: 'auto', md: 1 },
          '& img': {
            objectFit: 'cover',
          },
        }}
      >
        {/*<Box*/}
        {/*  component={'img'}*/}
        {/*  loading="lazy"*/}
        {/*  src={MainPhoto}*/}
        {/*  height={{ xs: 'auto', md: 1 }}*/}
        {/*  maxHeight={{ xs: 300, md: 1 }}*/}
        {/*  width={1}*/}
        {/*  maxWidth={1}*/}
        {/*/>*/}
      {/*</Box>*/}

        {/*Slider main container*/}

        <Box>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >


            {imgs.map((item, i) => (
              <SwiperSlide key={i}>
                <Box
                  component={'img'}
                  loading="lazy"
                  src={item.url}
                  // sx={{
                  //   backgroundImage:`url(${item.url})`,
                  // }}
                  height={{ xs: 'auto', md: 1 }}
                  minHeight={{ xs: 300, md: 400 }}
                  maxHeight={{ xs: 300, md: 450 }}
                  width={1}
                  maxWidth={1}
                  // display={'table-cell'}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        </Box>

    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        backgroundColor:'#fff5c7',
        // // backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5 ), rgba(255, 255, 255, 0.5 )),url(${bgImg})`,
        // backgroundImage: `url(${bgImg})`,
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat', // background-image가 컨테이너를 가득 채우지 못할 경우에도 반복하지 않는다.
        // backgroundPosition: 'left  top -1px',
        // justifyContent: 'center',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>

        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
          // minHeight={{ md: 400 }}
        >
          <Box
            // className={classes.root}
            width={1}
            order={{ xs: 2, md: 1 }}
            display={'flex'}
            alignItems={'center'}
            minHeight={{md:'400px'}}
            position={{xs:'absolute', md:'relative'}}
            sx={{
              // backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5 ), rgba(255, 255, 255, 0.5 )),url(${bgImg})`,
              backgroundImage: `url(${leftImg})`,
              backgroundSize: 'cover',
              // backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat', // background-image가 컨테이너를 가득 채우지 못할 경우에도 반복하지 않는다.
              backgroundPosition: 'left  top -1px',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Container>
              <LeftSide />
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: '0 0 100%', md: '0 0 50%' },
              position: 'relative',
              maxWidth: { xs: '100%', md: '50%' },
              order: { xs: 1, md: 2 },

            }}
          >
            <Box  //이거 지우면 사진 칸 사이즈 안으로 들어옴
              sx={{
                width: { xs: 1, md: '50vw' },
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box    //가운데 대각선으로 갈라지는 선
                  sx={{
                    overflow: 'hidden',
                    left: '0%',
                    width: 1,
                    height: 1,
                    position: { xs: 'relative', md: 'absolute' },
                    clipPath: {
                      xs: 'none',
                      md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                    },
                    shapeOutside: {
                      xs: 'none',
                      md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                    },
                  }}
                >
                {/*<Box    //가운데 대각선으로 갈라지는 선*/}
                {/*  sx={{*/}
                {/*    overflow: 'hidden',*/}
                {/*    left: '0%',*/}
                {/*    width: 1,*/}
                {/*    height: 1,*/}
                {/*    position: { xs: 'relative', md: 'relative' }, //absolute*/}
                {/*  }}*/}
                {/*>*/}
                  <RightSide />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );
};

export default Hero;
