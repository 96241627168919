import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import logoImg from 'img/컬러상단로고포돌이.png'
import NavItem from './components/NavItem';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 250, md: 250 }}
        >
          <Box
            component={'img'}
            src={logoImg
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'어린이집 소개'} items={blogPages} />
        </Box>
        <Box>
          <NavItem title={'교육 프로그램'} items={companyPages} />
        </Box>
        <Box>
          <NavItem title={'놀이 이야기'} items={accountPages} />
        </Box>
        <Box>
          <NavItem title={'어린이집 소식'} items={secondaryPages} />
        </Box>
        <Box>
          <NavItem title={'입학안내'} items={portfolioPages} />
        </Box>
        <Box marginTop={2}>
        {/*  <Button*/}
        {/*    size={'large'}*/}
        {/*    variant="outlined"*/}
        {/*    fullWidth*/}
        {/*    component="a"*/}
        {/*    href="https://thefront.maccarianagency.com/docs/introduction"*/}
        {/*    target={'blank'}*/}
        {/*  >*/}
        {/*    Documentation*/}
        {/*  </Button>*/}
        {/*</Box>*/}
        {/*<Box marginTop={1}>*/}
        {/*  <Button*/}
        {/*    size={'large'}*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    fullWidth*/}
        {/*    component="a"*/}
        {/*    target="blank"*/}
        {/*    href="https://mui.com/store/items/the-front-landing-page/"*/}
        {/*  >*/}
        {/*    Purchase now*/}
        {/*  </Button>*/}
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
