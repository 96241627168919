import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../../../img/mainLogo.webp'
import { NavItem } from './components';
import MainPhoto from '../../../../img/포돌이2.png';
import bgImg from '../../../../img/구름.png';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="police"
        width={{ xs: 400, md: 370 }}
      >
        <Box
          component={'img'}
          src={
            MainPhoto
          }
          height={1}
          width={1}
        />
      </Box>
        {/*<Box*/}
        {/*  // marginLeft={{sm:4}} //간격 좁혀달라는 요청사항 반영*/}
        {/*  height={1}*/}
        {/*  width={1}*/}
        {/*  href="/"*/}
        {/*  title="police"*/}
        {/*  width={{ xs: 500, sm:550, md: 400 }}*/}
        {/*  alignItems={{sm:'center'}}*/}

        {/*>*/}
        {/*  <Button style={{*/}
        {/*    fontFamily: '"Noto Sans KR", sans-serif',*/}
        {/*    fontWeight: 700,*/}
        {/*    fontSize:'25px',*/}
        {/*    color: '#003478',*/}
        {/*    // backgroundImage: `url(${MainPhoto})`,          backgroundRepeat: "no-repeat",*/}
        {/*    // backgroundSize: "cover",*/}
        {/*  }}*/}
        {/*    href="/" >*/}
        {/*    부산경찰청어린이집</Button>*/}
        {/*</Box>*/}


      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }} alignItems={'center'}
           // width={{ md: 1000 }}
      >
        <Box>
          <NavItem
            title={'어린이집 소개'}
            id={'landing-pages'}
            items={blogPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'보육 프로그램'}
            id={'company-pages'}
            items={companyPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'우리반 이야기'}
            id={'account-pages'}
            items={accountPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'어린이집 이야기'}
            id={'secondary-pages'}
            items={secondaryPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'입학안내'}
            id={'portfolio-pages'}
            items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
