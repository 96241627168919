/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import ButtonGroup from '@mui/material/ButtonGroup';
import Pagination from 'react-js-pagination';
import styled from 'styled-components'
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';


import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Form = () => {
  const [boardList, setBoardList] = useState([]);
  const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
  const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));

  const [viewGrade,setViewGrade] = useState("HUMAN");

  //페이징 처리
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(5);
  const handlePageChange = (page) => { setPage(page); };

  const getUserList = async () => {
    try {
      const resp = await (await axios.get("/api/userGradeList",{
        params:{grade:viewGrade},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setBoardList(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }

  const changeViewRole= (grade) => {
    setViewGrade(grade);
  }

  const showBtnName = (userId)=>{
    switch (viewGrade){
      case "HUMAN" :
        return  <Button variant="outlined" size="small" onClick={() => changUserGrade(userId)}>학부모로 등업</Button>
      case "MOM" :
        return  <Button variant="outlined" size="small" onClick={() => changUserGrade(userId)}>선생님으로 등업</Button>
      case "TEACHER":
        return <Button variant="outlined" size="small" onClick={() => changUserGrade(userId)}>학부모로 변경</Button>

    }
  }
  const changUserGrade = async (id) => {
    try {//백엔드에 데이터 보내기
      let data = {
        id : id,
        role : viewGrade,
      }

      const result = await axios.post('/api/changeGrade',  JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      alert('변경되었습니다.');
      window.location.reload();
    } catch (error) {
      alert(error.response.data.resultMsg);
      // window.location.reload();
      window.location.href = "/"
    }
  }
  const  checkTokenExpire = () => {
    const parseTokenExpiresIn = tokenExpiresIn;
    if(!parseTokenExpiresIn){
      
      return null;
    }

    const now = new Date();

    if (now.getTime()>parseTokenExpiresIn){
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenExpiresIn');
      
      return null
    }
    
    return parseTokenExpiresIn
  }

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdDate');
  const [dense, setDense] = React.useState(false);

  const headCells = [
    {
      id: 'nickName',
      numeric: false,
      disablePadding: true,
      label: '닉네임',
    },
    {
      id: 'createdDate',
      numeric: true,
      disablePadding: false,
      label: '가입일',
    },
    {
      id: 'role',
      numeric: true,
      disablePadding: false,
      label: '등급',
    },
    {
      id: 'useYn',
      numeric: false,
      disablePadding: false,
      label: '변경',
    },
  ];

//게시판 헤더
  function EnhancedTableHead(props) {
    const { order, orderBy } =
      props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
              {/*</TableSortLabel>*/}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }


  useEffect(() => {
    getUserList(); // 1) 게시글 상세정보 함수 호출
  }, [viewGrade]);

  useEffect(() => {
    checkTokenExpire(); // 1) 게시글 목록 조회 함수 호출
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (page) * items - boardList.length) : 0;
  return (
    <Box>
      <Box marginTop={{xs:2, md:2}}>
      </Box>

      {/*게시판목록*/}
      <Box marginTop={{ xs: 1, md: 2 }} marginLeft={{ xs: 1, md: 2 }}>

        <Box marginBottom={2}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            등급 변경
          </Typography>
        </Box>

        {/*등급별 버튼*/}
        <Grid item xs={12} marginBottom={2}>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button onClick={()=>changeViewRole("HUMAN")}>일반인</Button>
            <Button onClick={()=>changeViewRole("MOM")}>학부모</Button>
            <Button onClick={()=>changeViewRole("TEACHER")}>선생님</Button>
          </ButtonGroup>
        </Grid>

        {/*게시판목록 in */}
        <Paper sx={{ width: '98%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                rowCount={boardList.length}
              />
              <TableBody>
                {boardList.slice(
                  items * (page - 1),
                  items * (page - 1) + items,
                ).map((v, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      component={Box}
                      state={{ boardId: v.id }}
                      // onClick={(event) => handleClick(event, v.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={v.id}
                      sx={{ cursor: 'pointer' }}
                      style={{ textDecoration: 'none' }}
                    >
                      <TableCell padding="checkbox">
                        {/*<Checkbox*/}
                        {/*  color="primary"*/}
                        {/*  checked={isItemSelected}*/}
                        {/*  inputProps={{*/}
                        {/*    'aria-labelledby': labelId,*/}
                        {/*  }}*/}
                        {/*/>*/}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align='center'
                      >
                        {v.nickName}
                      </TableCell>
                      <TableCell align="right">{v.createdDate}</TableCell>
                      <TableCell align="right">{v.role}</TableCell>
                      {/*<TableCell align="center">{v.createdDate}</TableCell>*/}
                      <TableCell align="center">{showBtnName(v.id)}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <div style={{ width: '100%' }}>
          <Box alignContent={'center'}>
            <PaginationBox>
              <Pagination
                activePage={page}
                itemsCountPerPage={items}
                totalItemsCount={boardList.length - 1}
                pageRangeDisplayed={5}
                onChange={handlePageChange}>
              </Pagination>
            </PaginationBox>
          </Box>
        </div>


        {/*<FormControlLabel*/}
        {/*  control={<Switch checked={dense} onChange={handleChangeDense} />}*/}
        {/*  label="Dense padding"*/}
        {/*/>*/}
      </Box>
    </Box>
  );
};
const PaginationBox = styled.div`
    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    ul.pagination li {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 1px solid #e2e2e2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
    }

    ul.pagination li:first-child {
        border-radius: 5px 0 0 5px;
    }

    ul.pagination li:last-child {
        border-radius: 0 5px 5px 0;
    }

    ul.pagination li a {
        text-decoration: none;
        color: #337ab7;
        font-size: 1rem;
    }

    ul.pagination li.active a {
        color: white;
    }

    ul.pagination li.active {
        background-color: #337ab7;
    }

    ul.pagination li a:hover,
    ul.pagination li a.active {
        color: blue;
    }
`
export default Form;
