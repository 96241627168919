import React from 'react';

import {
  Intro as HelloIntro,
  EduGoal as Edu_EduGoal,
  SchoolIntro as Intro_SchoolIntro,
  SchoolEnv as Intro_SchoolEnv,
  ComeMap
} from 'intro';
const routes = [
  {
    path: '/intro/intro',
    renderer: (params = {}) => <HelloIntro {...params} />,
  },
  {
    path: '/intro/eduGoal',
    renderer: (params = {}) => <Edu_EduGoal {...params} />,
  },
  {
    path: '/intro/schoolIntro',
    renderer: (params = {}) => <Intro_SchoolIntro {...params} />,
  },
  {
    path: '/intro/schoolEnv',
    renderer: (params = {}) => <Intro_SchoolEnv {...params} />,
  },
  {
    path: '/intro/comeMap',
    renderer: (params = {}) => <ComeMap {...params} />,
  },
];

export default routes;
